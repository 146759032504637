<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { importAction } from '@/command/netTool'
export default {
  name: 'freightCouponUser',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/userFreightCoupon/page',
        current: 1,
        paramsValue: {
          freightCouponId: this.$route.query.id
        }
      })
    },
    getHeader() {
      return [
        {
          name: '手机号',
          key: 'userPhone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left'
        },
        {
          dataIndex: 'userPhone',
          title: '手机',
          align: 'left'
        },
        {
          dataIndex: 'usedNum',
          title: '使用数量',
          align: 'left'
        },
        {
          dataIndex: 'giveNum',
          title: '剩余数量',
          align: 'left',
          customRender: (text, record) => {
            return `${text - record.usedNum}`
          }
        },
        {
          dataIndex: 'giveNum',
          title: '发放数量',
          align: 'left'
        },
        {
          dataIndex: 'updateTime',
          title: '最后消费时间',
          align: 'left'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/userFreightCoupon',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '手机号',
              key: 'userPhone',
              labelAlign: 'right',
              type: 'input',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '发放数量',
              key: 'giveNum',
              labelAlign: 'right',
              type: 'input',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            }
          ]
        }
      ]
      return form
    },
    handlePopup(
      dataSource = {
        giveNum: ''
      }
    ) {
      let that = this
      let data = this.getDrawerForm(dataSource)
      apiTool.showDrawer({
        title: '设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data,
          form: dataSource,
          formProps: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          }
        },
        success: ({ data, setHidden }) => {
          let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
          if (!lxfs.test(data.userPhone)) {
            this.$message.warning('请填写或输入正确手机号！')
            return false
          }
          if (data.giveNum && !/^[0-9]*$/.test(data.giveNum)) {
            this.$message.warning('请填写或输入发放数量！')
            return false
          }
          api.command[data.id ? 'edit' : 'create']
            .call(that, {
              url: '/userFreightCoupon',
              params: {
                ...data,
                freightCouponId: that.$route.query.id
              }
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          }
        }
      ]
    },
    getRightButton() {
      return [
        // {
        //   name: '导入Excel',
        //   onClick: () => {
        //     importAction(
        //       '/api/farmUserBalance/importData?balanceId=' +
        //         this.$route.query.id
        //     )
        //       .then(() => {
        //         this.$message.success('导入成功')
        //         this.getInit()
        //       })
        //       .catch(err => {
        //         this.$message.error('导入失败：' + err.msg)
        //       })
        //   }
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        rightButton={this.getRightButton()}
      />
    )
  }
}
</script>
